.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #cfffc9;
  background: linear-gradient(to right, #e2e2e2, #c9d6ff);
}
.parent{
  background-color: #cfffc9;
  background: linear-gradient(to right, #e2e2e2, #c9d6ff);
}
.card {
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.arCardleft{
  padding: 40px;
}

.arCardRight {
  background-color: #135e3a;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top-left-radius: 30%;
  border-bottom-left-radius: 30%;
}
.enCardRight{
  padding: 40px;
}
.enCardLeft {
  background-color: #135e3a;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top-right-radius: 30%;
  border-bottom-right-radius: 30%;
}
.yellow{
  color:#FBCB1D;
}
.ksfj{
  color: #029cca;
  color: #ff009d;
}
.butn {
  background-color: #045f3e;
  color: #fff;
  font-size: 12px;
  padding: 10px 45px;
  border: 1px solid transparent;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-top: 10px;
  cursor: pointer;
}
.red{
  color: red;
}
.widthOfCard{
  width: 60%;
}
.container form{
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  height: 100%;
}
.container input{
  background-color: #eee;
  border: none;
  margin: 8px 0;
  padding: 10px 15px;
  font-size: 13px;
  border-radius: 8px;
  width: 100%;
  outline: none;
}
.ltr{
  font-family: "Overpass", sans-serif;
}
.rtl{
  font-family: 'Noto Kufi Arabic', sans-serif;
}
@media screen and (min-width:769px) and (max-width:1024px) {
  .widthOfCard{
    width: 90%;
  }

}

@media (max-width: 768px) {
  .enCardLeft {
    background-color: #135e3a;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-bottom-right-radius: 40%;
    border-bottom-left-radius: 40%;
    border-top-left-radius:0px;
    border-top-right-radius:0px;
    padding: 30px;
  }
  .arCardRight {
    background-color: #135e3a;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-bottom-left-radius: 40%;
    border-bottom-right-radius: 40%;
    border-top-left-radius:0px;
    border-top-right-radius:0px;
    padding:10% ;
  }
  .paddinge-0{
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .widthOfCard{
    width: 85%;
  }
}
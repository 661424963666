li a{
  color: rgb(4, 95, 62) !important;
  font-weight: 600 !important;
}
.linkSize{
  width: 12%;
  margin: 8px !important;
}
li.border:hover{
  border-color: #FBCB1D !important;
}
li a:hover{
  color:#FBCB1D !important;
}
.rtl{
  direction:rtl;
}
.ltr{
  direction:ltr;
}
.outline{
  color: rgb(4, 95, 62) !important;
  box-shadow: 0 0 0 0px rgb(4, 95, 62) !important;
}
@media screen and (min-width: 481px) and (max-width: 1000px) {
  .linkSize{
    width: 18%;
  }
  .toggler{
    padding: 0px;
  }
}

@media (max-width: 480px) {

.linkSize{
  width:25%;
}
.mForRes{
  margin: 24px !important;
  
}
.customNav-link{
  
  margin:7px;
}




}

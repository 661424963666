#home{
  background-image: url(../../img/green.webp);
  background-attachment: fixed;
  background-size: 100% 100%;
  z-index: -1;
  filter: brightness(80%);
}
.mainColor{
  color: #045f3e;
}
.mission{
  border: #FBCB1D 3px solid !important;
  border-radius: 10px !important;
}
#mission-values,.background{
  background-image: url(../../img/dark-green-texture.webp);
  background-size: 100% 100%;
}
.service-h{
  background-color:#FBCB1D !important;
}
.bg-darkGreen{
  background-color: #012518;
}
.pink{
  color: #dd318a;
}
.blue{
  color: #22a8d4;
}
.yellow{
  color: #FBCB1D !important;
}
.hov:hover{
  color: #FBCB1D;
}
.reviews{
  margin-top:30px;
}
.divHover{
  scale:0.9;
  transition: ease 0.7s all;
}
.divHover:hover{
  scale:1;
  transition: ease 0.7s all;
} 
.carousel-control-prev-icon,.carousel-control-next-icon {
  color: #FBCB1D;
  /* border: solid rgba(0, 0, 0, 0.616) 2px; */
  
}
.carouselControlPrev{
  margin-left: 250px;
}
.carouselControlNext{
  margin-right: 250px;
}
.divOfCarousel{
  height: 350px;
}
@media screen and (min-width:769px) and (max-width:1024px) {
  #carouselControlPrev,.carouselControlPrev{
    margin-left: 0px !important;
  }
  .carouselControlPrev{
    margin-left: 0px;
  }
  .carouselControlNext{
    margin-right: 0px;
  }
  #imgOfCarousel,.carouselForResponsive{
    width: 50% !important;
  }
  .mTop{
    margin-top: 16px !important;
  }
  .largeScreen{
    display: block !important;
    width: 100% !important;
  }

}

@media screen and (min-width:481px) and (max-width:768px) {
  #carouselControlPrev,.carouselControlPrev{
    margin-left: 0px !important;
  }
  .carouselControlPrev{
    margin-left: 0px;
  }
  .carouselControlNext{
    margin-right: 0px;
  }
  #imgOfCarousel,.carouselForResponsive{
    width: 50% !important;
  }
  .mTop{
    margin-top: 16px !important;
  }
}
@media (max-width: 480px) {
  .ulForResponsive{
    display:block !important;
    
  }
  .mTop{
    margin-top: 16px !important;
  }
  .marginForResponsive{
    margin:10px;
  }
  #imgOfCarousel,.carouselForResponsive{
    width: 70% !important;
  }
  .divOfCarousel{
    width: 100% !important;
    height: auto !important;
    
  }
  #carouselControlPrev,.carouselControlPrev{
    margin-left: 0px !important;
  }
  .carouselControlPrev{
    margin-left: 0px;
  }
  .carouselControlNext{
    margin-right: 0px;
  }
  #home{
    background-image: url(../../img/454754991_437332452620304_4097663442692767194_n.jpg);
    background-attachment: fixed;
    background-size: 100% 100%;
    z-index: -1;
    filter: brightness(80%);
  }
}
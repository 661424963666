.ltr{
  direction: ltr;
  font-family: "Overpass", sans-serif;
}
.rtl{
  direction: rtl;
  font-family: 'Noto Kufi Arabic', sans-serif;
}
.mainColor{
  color: #045f3e;
}
/* Firefox (uncomment to work in Firefox, although other properties will not work!)  */
/** {
  scrollbar-width: thin;
  scrollbar-color: #045F3E #DFE9EB;
}*/

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}
*::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #DFE9EB;
}

*::-webkit-scrollbar-track:hover {
  background-color: #DAE3E6;
}

*::-webkit-scrollbar-track:active {
  background-color: #B8C0C2;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #057F53;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #069661;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #045F3E;
}
